import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"terms"} />
		<Helmet>
			<title>
				Summit Haven Ski Retreat
			</title>
			<meta name={"description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:title"} content={"Summit Haven Ski Retreat"} />
			<meta property={"og:description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://laxigenturra.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://laxigenturra.com/img/6347621.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://laxigenturra.com/img/6347621.png"} />
			<meta name={"msapplication-TileImage"} content={"https://laxigenturra.com/img/6347621.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" color="--dark" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
				Şartlar ve Koşullar
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0" text-align="left">
				Lütfen Hizmetimizi kullanmadan önce bu hüküm ve koşulları dikkatlice okuyun.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Yorum ve Tanımlar
			</Text>
			<Text as="h3" font="--headline3" margin="10px 0 20px 0" text-align="left">
				Yorumlama
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
				Baş harfi büyük olan kelimeler aşağıdaki koşullar altında tanımlanan anlamlara sahiptir. Aşağıdaki tanımlar, tekil veya çoğul olarak görünmelerine bakılmaksızın aynı anlama gelecektir.
			</Text>
			<Text as="h3" font="--headline3" margin="40px 0 10px 0" text-align="left">
				Tanımlar
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bu Hüküm ve Koşulların amaçları doğrultusunda:
				<br />
				– İştirak, bir tarafı kontrol eden, tarafça kontrol edilen veya tarafla ortak kontrol altında olan bir kuruluş anlamına gelir; burada "kontrol", yöneticilerin veya diğer yönetim yetkilerinin seçilmesi için oy kullanma hakkına sahip hisselerin, öz sermaye payının veya diğer menkul kıymetlerin %50 veya daha fazlasına sahip olmak anlamına gelir.
				<br />
				{" "}– Ülke anlamına gelir: Türkiye
				<br />
				{" "}– Şirket (bu Sözleşmede "Şirket", "Biz", "Bize" veya "Bizim" olarak anılacaktır) laxigenturra.com'u ifade eder.
				<br />
				{" "}– Cihaz, bilgisayar, cep telefonu veya dijital tablet gibi Hizmete erişebilen herhangi bir cihaz anlamına gelir.
				<br />
				{" "}– Hizmet Web Sitesini ifade eder.
				<br />
				{" "}– Şartlar ve Koşullar ("Şartlar" olarak da anılır), Hizmetin kullanımına ilişkin olarak Siz ve Şirket arasındaki anlaşmanın tamamını oluşturan bu Şartlar ve Koşullar anlamına gelir. Bu Şartlar ve Koşullar sözleşmesi Şartlar ve Koşullar Oluşturucu yardımıyla oluşturulmuştur.
				<br />
				{" "}– Üçüncü Taraf Sosyal Medya Hizmeti, Hizmet tarafından görüntülenebilen, dahil edilebilen veya kullanıma sunulan üçüncü taraflarca sağlanan herhangi bir hizmet veya içerik (veri, bilgi, ürün veya hizmetler dahil) anlamına gelir.
				<br />
				{" "}– Siz, Hizmete erişen veya Hizmeti kullanan kişi veya söz konusu kişinin adına Hizmete eriştiği veya Hizmeti kullandığı şirket veya diğer tüzel kişilik anlamına gelir.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Teşekkür
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bunlar, bu Hizmetin kullanımını düzenleyen Hüküm ve Koşullar ile Siz ve Şirket arasında işleyen sözleşmedir. Bu Hüküm ve Koşullar, tüm kullanıcıların Hizmetin kullanımına ilişkin hak ve yükümlülüklerini belirler.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmete erişiminiz ve Hizmeti kullanımınız, bu Hüküm ve Koşulları kabul etmeniz ve bunlara uymanız koşuluna bağlıdır. Bu Hüküm ve Koşullar, Hizmete erişen veya Hizmeti kullanan tüm ziyaretçiler, kullanıcılar ve diğerleri için geçerlidir.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmete erişerek veya Hizmeti kullanarak bu Hüküm ve Koşullara tabi olmayı kabul edersiniz. Bu Hüküm ve Koşulların herhangi bir bölümüne katılmıyorsanız, Hizmete erişemezsiniz.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Siz 18 yaşın üzerinde olduğunuzu beyan edersiniz. Şirket, 18 yaşından küçüklerin Hizmeti kullanmasına izin vermez.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmete erişiminiz ve Hizmeti kullanımınız ayrıca Şirketin Gizlilik Politikasını kabul etmeniz ve buna uymanız koşuluna bağlıdır. Gizlilik Politikamız, Uygulamayı veya Web Sitesini kullandığınızda kişisel bilgilerinizin toplanması, kullanılması ve ifşa edilmesine ilişkin politikalarımızı ve prosedürlerimizi açıklar ve Size gizlilik haklarınız ve yasaların Sizi nasıl koruduğu hakkında bilgi verir. Lütfen Hizmetimizi kullanmadan önce Gizlilik Politikamızı dikkatlice okuyun.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Diğer Web Sitelerine Bağlantılar
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmetimiz, Şirkete ait olmayan veya Şirket tarafından kontrol edilmeyen üçüncü taraf web sitelerine veya hizmetlerine bağlantılar içerebilir.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Şirket, herhangi bir üçüncü taraf web sitesinin veya hizmetinin içeriği, gizlilik politikaları veya uygulamaları üzerinde hiçbir kontrole sahip değildir ve bunlar için hiçbir sorumluluk kabul etmez. Ayrıca, Şirketin, bu tür web sitelerinde veya hizmetlerde bulunan veya bunlar aracılığıyla sunulan bu tür içerik, mal veya hizmetlerin kullanımı veya bunlara güvenilmesinden kaynaklanan veya bunlarla bağlantılı olduğu iddia edilen herhangi bir hasar veya kayıptan doğrudan veya dolaylı olarak sorumlu veya yükümlü olmayacağını kabul ve beyan edersiniz.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Ziyaret ettiğiniz tüm üçüncü taraf web sitelerinin veya hizmetlerinin hüküm ve koşulları ile gizlilik politikalarını okumanızı önemle tavsiye ederiz.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Fesih
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bu Hüküm ve Koşulları ihlal etmeniz dahil ancak bununla sınırlı olmamak üzere herhangi bir nedenle, önceden bildirimde bulunmaksızın veya yükümlülük altına girmeksizin erişiminizi derhal sonlandırabilir veya askıya alabiliriz.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Fesih üzerine, Hizmeti kullanma hakkınız derhal sona erecektir.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Sorumluluğun Sınırlandırılması
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Uğrayabileceğiniz herhangi bir zarara bakılmaksızın, Şirketin ve tedarikçilerinin bu Şartların herhangi bir hükmü kapsamındaki tüm sorumluluğu ve yukarıda belirtilenlerin tümü için münhasır çözüm yolunuz, Hizmet aracılığıyla fiilen ödediğiniz tutarla veya Hizmet aracılığıyla herhangi bir şey satın almadıysanız 100 USD ile sınırlı olacaktır.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Yürürlükteki yasaların izin verdiği azami ölçüde, Şirket veya tedarikçileri hiçbir durumda herhangi bir özel, arızi, dolaylı veya sonuç olarak ortaya çıkan zararlardan (kar kaybı, veri veya diğer bilgi kaybı, iş kesintisi, kişisel yaralanma dahil ancak bunlarla sınırlı olmamak üzere) sorumlu tutulamaz, Hizmetin, üçüncü taraf yazılımının ve/veya Hizmetle birlikte kullanılan üçüncü taraf donanımının kullanımından veya kullanılamamasından kaynaklanan veya bunlarla herhangi bir şekilde ilgili olan veya bu Şartların herhangi bir hükmüyle bağlantılı olan gizlilik kaybı), Şirket veya herhangi bir tedarikçi bu tür zararların olasılığı konusunda bilgilendirilmiş olsa ve çözüm temel amacını yerine getiremese bile.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bazı eyaletler, zımni garantilerin hariç tutulmasına veya arızi veya dolaylı zararlar için sorumluluğun sınırlandırılmasına izin vermez, bu da yukarıdaki sınırlamalardan bazılarının geçerli olmayabileceği anlamına gelir. Bu eyaletlerde, her bir tarafın sorumluluğu yasaların izin verdiği en geniş ölçüde sınırlandırılacaktır.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				"OLDUĞU GİBİ" ve "MEVCUT OLDUĞU GİBİ" Feragatnamesi
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmet Size "OLDUĞU GİBİ" ve "MEVCUT OLDUĞU GİBİ" ve herhangi bir garanti olmaksızın tüm hatalar ve kusurlarla birlikte sağlanmaktadır. Yürürlükteki yasaların izin verdiği azami ölçüde, Şirket, kendi adına ve Bağlı Kuruluşları ve onların ilgili lisans verenleri ve hizmet sağlayıcıları adına, tüm zımni satılabilirlik, belirli bir amaca uygunluk, mülkiyet ve ihlal etmeme garantileri ve işlemlerin seyri, performans seyri, kullanım veya ticari uygulamalardan kaynaklanabilecek garantiler dahil olmak üzere, Hizmetle ilgili olarak açık, zımni, yasal veya başka türlü tüm garantileri açıkça reddeder. Yukarıda belirtilenlerle sınırlı olmaksızın, Şirket, Hizmetin gereksinimlerinizi karşılayacağına, amaçlanan sonuçlara ulaşacağına, diğer yazılımlar, uygulamalar, sistemler veya hizmetlerle uyumlu olacağına veya çalışacağına, kesintisiz çalışacağına, herhangi bir performans veya güvenilirlik standardını karşılayacağına veya hatasız olacağına veya herhangi bir hata veya kusurun düzeltilebileceğine veya düzeltileceğine dair hiçbir garanti veya taahhüt vermez ve hiçbir beyanda bulunmaz.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Yukarıda belirtilenleri sınırlamaksızın, ne Şirket ne de Şirketin herhangi bir sağlayıcısı, açık veya zımni herhangi bir beyan veya garanti vermez: (i) Hizmetin işleyişi veya kullanılabilirliği veya burada yer alan bilgiler, içerik ve materyaller veya ürünlerle ilgili olarak; (ii) Hizmetin kesintisiz veya hatasız olacağı; (iii) Hizmet aracılığıyla sağlanan herhangi bir bilgi veya içeriğin doğruluğu, güvenilirliği veya güncelliği ile ilgili olarak; veya (iv) Hizmetin, sunucularının, içeriğinin veya Şirketten veya Şirket adına gönderilen e-postaların virüs, komut dosyası, truva atı, solucan, kötü amaçlı yazılım, zaman bombası veya diğer zararlı bileşenlerden arınmış olduğu.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bazı yargı bölgeleri, belirli garanti türlerinin hariç tutulmasına veya bir tüketicinin geçerli yasal haklarına ilişkin sınırlamalara izin vermez, bu nedenle yukarıdaki istisnaların ve sınırlamaların bir kısmı veya tamamı Sizin için geçerli olmayabilir. Ancak böyle bir durumda, bu bölümde belirtilen istisnalar ve sınırlamalar, yürürlükteki yasalar uyarınca uygulanabilecek en geniş ölçüde uygulanacaktır.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Geçerli Kanun
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Ülkenin kanunları, kanunlar ihtilafı kuralları hariç olmak üzere, bu Şartları ve Hizmeti kullanımınızı yönetecektir. Uygulamayı kullanımınız diğer yerel, eyalet, ulusal veya uluslararası yasalara da tabi olabilir.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Anlaşmazlıkların Çözümü
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Hizmet hakkında herhangi bir endişeniz veya anlaşmazlığınız varsa, öncelikle Şirket ile iletişime geçerek anlaşmazlığı gayri resmi olarak çözmeye çalışmayı kabul edersiniz.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Bölünebilirlik ve Feragat
			</Text>
			<Text as="h3" font="--headline3" margin="10px 0 20px 0" text-align="left">
				Bölünebilirlik
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bu Şartların herhangi bir hükmünün uygulanamaz veya geçersiz olduğuna karar verilirse, söz konusu hüküm, yürürlükteki yasalar uyarınca mümkün olan en geniş ölçüde söz konusu hükmün amaçlarını gerçekleştirecek şekilde değiştirilecek ve yorumlanacak ve geri kalan hükümler tam olarak yürürlükte kalmaya devam edecektir.
			</Text>
			<Text as="h3" font="--headline3" margin="10px 0 20px 0" text-align="left">
				Feragat
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Burada belirtilenler dışında, bu Koşullar kapsamında bir hakkın kullanılmaması veya bir yükümlülüğün yerine getirilmesinin istenmemesi, taraflardan birinin daha sonra herhangi bir zamanda söz konusu hakkı kullanma veya söz konusu yükümlülüğü yerine getirmesini isteme hakkını etkilemeyecek veya bir ihlalden feragat edilmesi, sonraki herhangi bir ihlalden feragat edildiği anlamına gelmeyecektir.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Çeviri Yorumlama
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bu Hüküm ve Koşullar, Hizmetimizde Size sunmuş olmamız halinde tercüme edilmiş olabilir. Bir anlaşmazlık durumunda orijinal İngilizce metnin geçerli olacağını kabul edersiniz.
			</Text>
			<Text as="h2" font="--headline2" margin="10px 0 20px 0" text-align="left">
				Bu Hüküm ve Koşullardaki Değişiklikler
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Tamamen kendi takdirimize bağlı olarak, bu Şartları herhangi bir zamanda değiştirme veya değiştirme hakkımızı saklı tutarız. Bir revizyon önemliyse, yeni şartların yürürlüğe girmesinden önce en az 30 gün önceden bildirimde bulunmak için makul çabayı göstereceğiz. Neyin önemli bir değişiklik teşkil ettiği tamamen Bizim takdirimize bağlı olarak belirlenecektir.
			</Text>
			<Text as="p" font="--lead" margin="10px 0 20px 0" text-align="left">
				Bu revizyonlar yürürlüğe girdikten sonra Hizmetimize erişmeye veya kullanmaya devam ederek, revize edilmiş şartlara bağlı kalmayı kabul edersiniz. Yeni şartları kısmen veya tamamen kabul etmiyorsanız, lütfen web sitesini ve Hizmeti kullanmayı bırakın.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});